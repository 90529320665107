"use client";

import Image from "next/image";
import { FC } from "react";

import { useTranslations } from "next-intl";

import { styles } from "./styles";

import { ErrorLayout } from "@/shared/ui/Error/ErrorLayout";

import NotFoundImage from "@/public/img/errors/404.png";

export const NotFound: FC = () => {
  const t = useTranslations();
  return (
    <ErrorLayout
      title={t("ERROR_404")}
      wrapperSx={styles.container}
      subtitle={t("PAGE_NOT_FOUND")}
      linkText={t("GO_TO_HOMEPAGE")}
      text={t("UNFORTUNATELY_THE_REQUESTED_PAGE_WAS_NOT_FOUND")}
      image={
        <Image
          width={280}
          height={160}
          alt="Ошибка 404"
          src={NotFoundImage}
          style={{
            height: "auto",
            maxWidth: "100%",
          }}
        />
      }
    />
  );
};
